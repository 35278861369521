@use 'common'


@font-face
	font-family: 'sathu'
	src: url('/fonts/sathu-webfont.woff2') format('woff2'), url('/fonts/sathu-webfont.woff') format('woff')
	font-weight: normal
	font-style: normal

html,
body
	padding: 0
	margin: 0
	font-family: sathu, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif
	background-color: common.$mainColor
	position: relative
a
	color: inherit
	text-decoration: none

*
	box-sizing: border-box
